// this file was generated from 
// https://grommet-theme-builder.netlify.app/
// by Export diff
import { deepFreeze } from "grommet/utils"

// HPE Theme
export const customTheme = deepFreeze(
  {
  "global": {
    "font": {
       "family": 'Roboto',
       "size": '18px',
       "height": '20px',
     },
    "colors": {
      "brand": "#01a982",
      "focus": "#2AD2C9",
      "accent-1": "#2AD2C9",
      "accent-2": "#614767",
      "accent-3": "#ff8d6d",
      "neutral-1": "#425563",
      "neutral-2": "#5F7A76",
      "neutral-3": "#80746E",
      "neutral-4": "#767676",
      "status-critical": "#F04953",
      "status-error": "#F04953",
      "status-warning": "#FFD144",
      "status-ok": "#01a982"
    }
  },
  "anchor": {
    "textDecoration": "underline",
    "color": {
      "dark": "#FFFFFF",
      "light": "#000000"
    }
  },
  "button": {
    "border": {
      "radius": "0px"
    }
  },
  "clock": {
    "analog": {
      "second": {
        "color": {
          "dark": "#01a982",
          "light": "#01a982"
        }
      }
    }
  }
}
);



/* Material Light Theme
export const customTheme = deepFreeze(
  {
  "global": {
    "font": {
       "family": 'Roboto',
       "size": '18px',
       "height": '20px',
     },
    "colors": {
      "active": {
        "0": "r",
        "1": "g",
        "2": "b",
        "3": "a",
        "4": "(",
        "5": "2",
        "6": "2",
        "7": "1",
        "8": ",",
        "9": "2",
        "10": "2",
        "11": "1",
        "12": ",",
        "13": "2",
        "14": "2",
        "15": "1",
        "16": ",",
        "17": "0",
        "18": ".",
        "19": "5",
        "20": ")",
        "light": "#f50057",
        "dark": "#ff4081"
      },
      "brand": "#00ABD4",
      "control": {
        "dark": "#ffffff",
        "light": "#00ABD4"
      },
      "focus": "#2AD2C9",
      "accent-1": "#2AD2C9",
      "accent-2": "#FFC107",
      "accent-3": "#9C27B0",
      "accent-4": "#673AB7",
      "neutral-1": "#795548",
      "neutral-2": "#009688",
      "neutral-3": "#8BC34A",
      "neutral-4": "#CDDC39",
      "status-critical": "#FF4081",
      "status-error": "#F44336",
      "status-warning": "#FFEB3B",
      "status-ok": "#4CAF50",
      "status-unknown": "#9E9E9E",
      "status-disabled": "#9E9E9E"
    },
    "control": {
      "border": {
        "radius": "0px"
      }
    },
    "drop": {
      "background": "#f8f8f8"
    },
    "focus": {
      "border": {
        "color": "#f50057"
      }
    },
    "hover": {
      "background": {
        "dark": "#ff4081",
        "light": "#f50057"
      }
    }
  },
  "anchor": {
    "color": {
      "dark": "#7986cb",
      "light": "#3f51b5"
    }
  },
  "button": {
    "border": {
      "radius": "0px"
    }
  },
  "checkBox": {
    "check": {
      "radius": "0px"
    },
    "toggle": {
      "color": {
        "dark": "#bdbdbd",
        "light": "#00ABD4"
      }
    }
  },
  "radioButton": {
    "border": {
      "color": {
        "light": "rgba(0, 98, 186, 0.5)"
      }
    }
  }
}
);
*/
import React, { useState } from "react";
//import { ReactComponent as ReactLogo } from './oregon-tree.svg';
import { OregonTree } from "./OregonTree.js";

import {
  Box,
  Button,
  Collapsible,
  Footer,
  Heading,
  Grommet,
  Layer,
  Text,
  ResponsiveContext
} from 'grommet';
import { FormClose, Menu, TreeOption } from 'grommet-icons';
import { customTheme } from './theme'
// const theme = {
//   global: {
//     font: {
//       family: 'Roboto',
//       size: '18px',
//       height: '20px',
//     },
//   },
// };

//import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

import { Timeline } from 'react-twitter-widgets'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-272015-24');
ReactGA.pageview(window.location.pathname + window.location.search);



const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
);

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
  <Grommet theme={customTheme} themeMode="dark">
    <ResponsiveContext.Consumer>
      {size => (
        <Box fill>
          <AppBar>
            { /* <Heading level='3' margin='none'> <TreeOption /> License Plate Watcher</Heading> */ } 
            <Heading level='3' margin='none'> <OregonTree size='medium' /> License Plate Watcher</Heading>
            <Button
              icon={<Menu />}
              onClick={() => setShowSidebar(!showSidebar)}
            />
          </AppBar>
          <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
          <Box flex align='center' justify='center' pad='medium'>
            { /*<TreeOption color='brand' size='xlarge' />*/ }
            <br />
            { /* <ReactLogo /> */ }
            <OregonTree color='brand' size='xlarge' />
            <br />

            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'OregonPlates'
              }}
              options={{
                height: '725',
                width: '800'
              }}
            />
          </Box>
          {(!showSidebar || size !== 'small') ? (
            <Collapsible direction="horizontal" open={showSidebar}>
              <Box
                flex
                width='medium'
                background='light-2'
                elevation='small'
                align='center'
                justify='center'
              >
                sidebar
              </Box>
            </Collapsible>
          ): (
            <Layer>
              <Box
                background='light-2'
                tag='header'
                justify='end'
                align='center'
                direction='row'
              >
                <Button
                  icon={<FormClose />}
                  onClick={() => setShowSidebar(false)}
                />
              </Box>
              <Box
                fill
                background='light-2'
                align='center'
                justify='center'
              >
                sidebar
              </Box>
            </Layer>
          )}
        </Box>
       </Box>
      )}
    </ResponsiveContext.Consumer> 
        <Footer background="brand" justify="center" pad="small">
      <Text textAlign="center" size="small" pad="small">
        © 2020 <strong>Copyright</strong> Oregon License Plate Watcher<br/>
      </Text>
    </Footer> 
  </Grommet>
  );
}

export default App;
